import {Injectable} from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import {Observable, tap} from 'rxjs';
import {Router} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';
import {AuthenticationService} from '../shared/services/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private readonly authenticationService: AuthenticationService) {
    }

    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authHeader = this.authenticationService.getAuthorizationHeader();

        const authReq = httpRequest.clone({
            setHeaders: {
                'Authorization': authHeader
            }
        });

        return next.handle(authReq);
    }

}

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private readonly oauthService: OAuthService,
                private readonly router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // do stuff with response if you want
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                switch (err.status) {
                    case 401:
                        console.log(err.message);
                        this.oauthService.refreshToken();
                        this.router.navigateByUrl('');
                        break;
                    case 500:
                    case 501:
                    case 502:
                    case 503:
                    case 504:
                    case 505:
                        console.log(err.message);
                }
            }
        }));
    }

}
