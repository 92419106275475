import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {lastValueFrom, Observable, of} from 'rxjs';
import {UtilitiesService} from '../global/utilities.service';
import {SessionStorageService} from '../global/session-storage.service';
import {ConsultantUiConstants} from '../../modules/consultant-ui/consultant-ui.constants';
import {share} from 'rxjs/operators';
import {
    AppointmentResult,
    BookAppointmentRequestInterface,
    DateTime,
    ProgramType,
    Tenant,
    WeekAvailabilityRequestInterface
} from '../../interfaces/appointment.interface';

const COACH_WEB_API_URLS = {
    base: 'api/ecoach/v1/',
    getActivityProtocolSummary: 'activity-protocol/summary',
    getActivityProtocolDetails: 'activity-protocol/details',
    getHealthObjective: 'health-objective',
    getStopSmokingDetails: 'stop-smoking',
};

@Injectable({
    providedIn: 'root'
})
export class AppointmentApiService {

    private readonly appointmentApiUrls = {
        base: 'api/v1/',
        deleteAssignments: 'appointments/assignments',
        deleteAppointments: 'availabilities',
        getAssignments: 'appointments/assignments',
        getAppointmentDetails: 'appointments/',
        getAvailable: 'available',
        getConsultantAvailabilityByDate: 'availabilities/',
        getCalendar: 'availability-weeks/',
        getConsultantInfo: 'consultants/me',
        getConsultants: 'consultants',
        getProtocolHistory: 'appointments/protocols',
        getSlots: 'availability-slots',
        postCreateAppointment: 'availabilities',
        postReassignAppointmentsToUser: 'appointments/assignments',
        // TODO: remove reserve
        postReserve: 'appointments',
        postBookAppointment: 'appointments',
        postUpdateAvailability: 'availability-weeks',
        protocol: 'appointments/{appointmentUuid}/protocol', // PUT & GET
        putConfirmAssignment: 'appointments/assignments',
    };

    constructor(private readonly http: HttpClient,
                private readonly util: UtilitiesService,
                private readonly session: SessionStorageService) {
    }

    public getConsultants(programType?: string): Observable<any> {
        let httpParams = new HttpParams();

        if (programType) {
            httpParams = httpParams.append('programType', programType);
        }

        return this.http.get(`${environment.apiUrl}`
            + this.appointmentApiUrls.base
            + this.appointmentApiUrls.getConsultants,
            {params: httpParams});
    }

    public getSlots(config: { duration: number; shiftStart: string; shiftEnd: string; }): Observable<any> {
        const slots = this.session.get(ConsultantUiConstants.sessionStorage.timeSlots);

        if (!slots) {
            const timeSlotsObservable = this.http.get(`${environment.apiUrl}`
                + this.appointmentApiUrls.base
                + this.appointmentApiUrls.getSlots)
                .pipe(
                    share()
                );

            lastValueFrom(timeSlotsObservable)
                .then((timeSlots: { slots: string[], shiftTimeSlots: string[] }) => {
                    this.session.save(ConsultantUiConstants.sessionStorage.timeSlots, timeSlots);
                });

            return timeSlotsObservable;
        } else {
            return of(slots);
        }
    }

    public getShiftTimeItems(config: { duration: number; shiftStart: string; shiftEnd: string; }): Observable<any> {
        return this.http.get(`${environment.apiUrl}`
            + this.appointmentApiUrls.base
            + this.appointmentApiUrls.getSlots)
            .pipe(
                share()
            );
    }

    public getCalendar(dateString: string, user?: string): Observable<any> {
        let httpParams = new HttpParams();

        if (user) {
            httpParams = httpParams.append('user', user);
        }

        return this.http.get(`${environment.apiUrl}`
            + this.appointmentApiUrls.base
            + this.appointmentApiUrls.getCalendar
            + dateString,
            {params: httpParams});
    }

    public getConsultantAvailabilityByDate(name: string, dateString: string): Observable<any> {
        let httpParams = new HttpParams();

        if (name) {
            httpParams = httpParams.append('consultantUsername', name);
        }

        return this.http.get(`${environment.apiUrl}`
            + this.appointmentApiUrls.base
            + this.appointmentApiUrls.getConsultantAvailabilityByDate
            + dateString,
            {params: httpParams});
    }

    // TODO: remove
    getAvailable() {
        return this.http.get(`${environment.apiUrl}`
            + this.appointmentApiUrls.base
            + this.appointmentApiUrls.getAvailable);
    }

    public postCreateAppointment(slots: { dateTime: string }[], date: Date): Observable<any> {
        const dateTimes = UtilitiesService.formatDateAndTimeForRequest(slots, UtilitiesService.formatDateForRequest(date));

        return this.http.post(`${environment.apiUrl}`
            + this.appointmentApiUrls.base
            + this.appointmentApiUrls.postCreateAppointment,
            {dateTimes});
    }

    public postUpdateAvailability(weeks: WeekAvailabilityRequestInterface[]): Observable<any> {
        return this.http.post(`${environment.apiUrl}`
            + this.appointmentApiUrls.base
            + this.appointmentApiUrls.postUpdateAvailability,
            {weeks});
    }

    public postReassignAppointmentsToUser(appointmentIds: string[], consultantUsername: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}`
            + this.appointmentApiUrls.base
            + this.appointmentApiUrls.postReassignAppointmentsToUser,
            {
                uuids: appointmentIds,
                toUsername: consultantUsername
            });
    }

    public deleteAssignments(uuids: string[]): Observable<any> {
        return this.http.post(`${environment.apiUrl}`
            + this.appointmentApiUrls.base
            + this.appointmentApiUrls.deleteAssignments,
            {body: {
                    uuids: [uuids]
                }
            });
    }

    public deleteAppointments(dateTimes: DateTime[]): Observable<any> {
        return this.http.delete(`${environment.apiUrl}`
            + this.appointmentApiUrls.base
            + this.appointmentApiUrls.deleteAppointments,
            {body: {dateTimes}});
    }

    public postReserveAppointment(appointment: BookAppointmentRequestInterface): Observable<any> {
        return this.http.post(`${environment.apiUrl}`
            + this.appointmentApiUrls.base
            + this.appointmentApiUrls.postReserve,
            appointment);
    }

    public postBookAppointment(externalId: any, dateTime: string, tenant: Tenant, programType: ProgramType): Observable<any> {
        const requestBody = {
            dateTime: dateTime,
            externalId: externalId,
            programType: programType,
            tenant: tenant
        };


        return this.http.post(`${environment.apiUrl}`
            + this.appointmentApiUrls.base
            + this.appointmentApiUrls.postBookAppointment,
            requestBody);
    }

    public rejectAssignments(uuids: string[]): Observable<any> {
        return this.http.delete(`${environment.apiUrl}`
            + this.appointmentApiUrls.base
            + this.appointmentApiUrls.deleteAssignments,
            {body: {
                uuids: uuids
            }}
        );
    }

    public confirmAssignments(uuids: string[]): Observable<any> {
        return this.http.put(`${environment.apiUrl}`
            + this.appointmentApiUrls.base
            + this.appointmentApiUrls.putConfirmAssignment,
            {uuids: uuids});
    }

    public getAppointmentDetails(uuid: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}`
            + this.appointmentApiUrls.base
            + this.appointmentApiUrls.getAppointmentDetails
            + uuid);
    }

    public getCoachWebActivityHistory(uuid: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}`
            + COACH_WEB_API_URLS.base
            + COACH_WEB_API_URLS.getActivityProtocolSummary
            + `?id=${uuid}`);
    }

    public getCoachWebActivityDetails(uuid: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}`
            + COACH_WEB_API_URLS.base
            + COACH_WEB_API_URLS.getActivityProtocolDetails
            + `?id=${uuid}`);
    }

    public getCoachWebHealthObjective(uuid: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}`
            + COACH_WEB_API_URLS.base
            + COACH_WEB_API_URLS.getHealthObjective
            + `?id=${uuid}`);
    }

    public getCoachWebStopSmokingDetails(uuid: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}`
            + COACH_WEB_API_URLS.base
            + COACH_WEB_API_URLS.getStopSmokingDetails
            + `?id=${uuid}`);
    }

    public getInterviewProtocolById(appointmentId: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}`
            + this.appointmentApiUrls.base
            + this.appointmentApiUrls.protocol.replace('{appointmentUuid}', appointmentId));
    }

    public getProtocolHistoryByUserId(externalId: string, tenant: Tenant): Observable<any> {
        const params = new HttpParams()
            .append('userExternalId', externalId)
            .append('tenant', tenant);

        return this.http.get(`${environment.apiUrl}`
            + this.appointmentApiUrls.base
            + this.appointmentApiUrls.getProtocolHistory,
            {params: params});
    }

    public saveAppointmentProtocol(appointmentId: string, result: AppointmentResult, durationInMinutes?: number, notes?: string): Observable<any> {
        return this.http.put(`${environment.apiUrl}`
            + this.appointmentApiUrls.base
            + this.appointmentApiUrls.protocol.replace('{appointmentUuid}', appointmentId.toString()),
            {result, durationInMinutes, notes});
    }

    public getAssignments(): Observable<any> {
        return this.http.get(`${environment.apiUrl}`
            + this.appointmentApiUrls.base
            + this.appointmentApiUrls.getAssignments
        );
    }

    public getAssignedCount(): Observable<any> {
        return this.http.get(`${environment.apiUrl}`
            + this.appointmentApiUrls.base
            + this.appointmentApiUrls.getAssignments
        );
    }

    public getConsultantInfo(): Observable<any> {
        return this.http.get(`${environment.apiUrl}`
            + this.appointmentApiUrls.base
            + this.appointmentApiUrls.getConsultantInfo,
        );
    }
}
