import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';

import {ReactiveFormsModule} from '@angular/forms';
import {NgbModalModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ToastrModule} from 'ngx-toastr';

import {TkUiComponentsModule} from 'tk-ui-components';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {DefaultHttpInterceptor} from './interceptor/defaultHttp.interceptor';

import {DashboardComponent} from './components/global/dashboard/dashboard.component';
import {HeaderComponent} from './components/global/header/header.component';
import {LoginComponent} from './modules/login/login.component';

import {ConsultantUiModule} from './modules/consultant-ui/consultant-ui.module';
import {SharedModule} from './modules/shared/shared.module';
import {AuthConfig, OAuthModule, OAuthStorage} from 'angular-oauth2-oidc';
import {authConfig} from '../authConfigs/auth.config';
import {StorageService} from './shared/services/storage.service';
import {StateHandlerService, StateHandlerServiceImpl} from './shared/services/statehandler.service';
import {StateHandlerProcessorService, StateHandlerProcessorServiceImpl} from './shared/services/statehandler-processor.service';
import {environment} from '../environments/environment';
import {AuthInterceptor, JwtInterceptor} from './interceptor/Auth.interceptor';

registerLocaleData(localeDe);

const stateHandlerFn = (stateHandler: StateHandlerService) => {
    return () => {
        return stateHandler.initStateHandler();
    };
};

@NgModule({ declarations: [
        AppComponent,
        DashboardComponent,
        HeaderComponent,
        LoginComponent,
    ],
    exports: [],
    bootstrap: [
        AppComponent
    ], imports: [AppRoutingModule,
        BrowserModule,
        ConsultantUiModule,
        BrowserAnimationsModule,
        NgbModalModule,
        NgbModule,
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: [
                    authConfig.issuer + '/admin/v1',
                    authConfig.issuer + '/management/v1',
                    authConfig.issuer + '/auth/v1/',
                    authConfig.issuer + '/oidc/v1/',
                    authConfig.issuer + '/oauth/v2/',
                    environment.apiUrl + '/api/v1/',
                ],
                sendAccessToken: true,
            },
        }),
        ReactiveFormsModule,
        SharedModule.forRoot(),
        TkUiComponentsModule.forRoot(),
        ToastrModule.forRoot({
            closeButton: true,
            timeOut: 6000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
        })], providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: stateHandlerFn,
            multi: true,
            deps: [StateHandlerService],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DefaultHttpInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useValue: 'de_DE'
        },
        {
            provide: AuthConfig,
            useValue: authConfig,
        },
        {
            provide: OAuthStorage,
            useClass: StorageService,
        },
        {
            provide: StateHandlerProcessorService,
            useClass: StateHandlerProcessorServiceImpl,
        },
        {
            provide: StateHandlerService,
            useClass: StateHandlerServiceImpl,
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
}
