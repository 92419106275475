import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {UtilitiesService} from './utilities.service';
import {Tenant} from '../../interfaces/appointment.interface';
import {Observable} from 'rxjs';
import {AccountConstants} from '../../constants/account.constants';

const API_URLS = {
    accountDetails: {
        TK_GESUNDHEITSCOACH_WEB: 'api/ecoach/v1/account/details?id=',
        TK_COACH_APP: 'api/tkgc-app/v1/account/details?accountId=',
        TK_RAUCHFREI_APP: 'api/tk-rf/v1/account/details?accountId=',
    },
    accountSearch: 'api/v1/consultants/user-search',
    assessments: {
        TK_COACH_APP: 'api/tkgc-app/v1/assessment',
    },
    activities: {
        TK_COACH_APP: 'api/tkgc-app/v1/activity-protocol/summary',
    },
    blockAccount: 'block/',
    lastUserAppointments: 'api/v1/consultants/me/past-users',
};

@Injectable({
    providedIn: 'root'
})
export class AccountService {

    constructor(private readonly http: HttpClient) {
    }

    public searchPhoneConsultationUser(searchValue: string, page = 0): Observable<any> {
        const url = `${environment.apiUrl}${API_URLS.accountSearch}?searchTerm=${searchValue || ''}&page=${page}`;
        return this.http.get(url);
    }

    public getActivitiesByAccount(accountId: string, tenant = Tenant.TK_COACH_APP, page = 0, size = 100): Observable<any> {
        return this.http.get(`${environment.apiUrl}`
            + API_URLS.activities[tenant]
            + `?accountId=${accountId}`);
    }

    public getAssessmentsByAccount(accountId: string, tenant = Tenant.TK_COACH_APP): Observable<any> {
        return this.http.get(`${environment.apiUrl}`
            + API_URLS.assessments[tenant]
            + `?accountId=${accountId}`
            + `&appAssessmentTypes=UNKNOWN,WELL_BEING,DAILY_ACTIVITY,MENTAL_HEALTH_SCORE`);
    }

    public getLastFiveAppointments(): Observable<any> {
        return this.http.get(`${environment.apiUrl}` + API_URLS.lastUserAppointments);
    }

    public getAccountDetailsById(accountId: string, tenant: Tenant): Observable<any> {
        const url = `${environment.apiUrl}${API_URLS.accountDetails[tenant]}${accountId}`;
        return this.http.get(url);
    }
}
