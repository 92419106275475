import { HttpResponse } from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DownloadService {

    constructor() {
    }

    public downloadText(response: HttpResponse<String>) {
        const responseHeader = response.headers.get('Content-Disposition');
        const filename = responseHeader.substr(responseHeader.indexOf('=') + 1).replace(/"/g, '');
        const blob = new Blob([response.body as string], {type: 'text/csv'});

        const link = <HTMLAnchorElement>document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

}
