import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';

import {TkUiComponentsModule} from 'tk-ui-components';
import {SharedModule} from '../shared/shared.module';

import {AppointmentsComponent} from './appointments/appointments.component';
import {AppointmentAvailabilityComponent} from './appointments/appointment-availability/appointment-availability.component';
import {AppointmentManagementComponent} from './appointments/appointment-management/appointment-management.component';
import {AppointmentStatusComponent} from './appointments/appointment-status/appointment-status.component';
import {CalendarComponent} from '../../components/ui-components/calendar/calendar.component';
import {EmployeeAppointmentsColumnComponent} from './employee-appointments-column/employee-appointments-column.component';

import {ParticipantsComponent} from './participants/participants.component';
import {
    ParticipantConversationProtocolComponent
} from './participants/participant-conversation-protocol/participant-conversation-protocol.component';
import {ConversationGuideComponent} from './participants/conversation-guide/conversation-guide.component';
import {
    AppointmentConfirmAssignmentsComponent
} from './appointments/appointment-confirm-assignments/appointment-confirm-assignments.component';
import {
    AppointmentStatusTableComponent
} from './appointments/appointment-status/appointment-status-table/appointment-status-table.component';
import {
    AppointmentConsultantAvailabilityTableComponent
} from './appointments/appointment-consultant-availability-table/appointment-consultant-availability-table.component';
import {MonthCalendarComponent} from '../../components/ui-components/calendar/month-calendar/month-calendar.component';
import {
    MonthCaptionTemplateDirective
} from 'src/app/components/ui-components/calendar/month-calendar/directives/month-caption-template.directive';
import {
    DayOfWeekCaptionTemplateDirective
} from 'src/app/components/ui-components/calendar/month-calendar/directives/day-of-week-caption-template.directive';
import {DayTemplateDirective} from 'src/app/components/ui-components/calendar/month-calendar/directives/day-template.directive';

import {
    StopSmokingHealthObjectiveDetailsComponent
} from './participants/participant-conversation-protocol/health-objective-details/stop-smoking-health-objective-details/stop-smoking-health-objective-details.component';
import {
    StopSmokingHealthObjectiveDetailsListComponent
} from './participants/participant-conversation-protocol/health-objective-details/stop-smoking-health-objective-details-list/stop-smoking-health-objective-details-list.component';

@NgModule({ declarations: [
        AppointmentAvailabilityComponent,
        AppointmentConfirmAssignmentsComponent,
        AppointmentConsultantAvailabilityTableComponent,
        AppointmentManagementComponent,
        AppointmentStatusComponent,
        AppointmentStatusTableComponent,
        AppointmentsComponent,
        CalendarComponent,
        ConversationGuideComponent,
        DayOfWeekCaptionTemplateDirective,
        DayTemplateDirective,
        EmployeeAppointmentsColumnComponent,
        MonthCalendarComponent,
        MonthCaptionTemplateDirective,
        ParticipantConversationProtocolComponent,
        ParticipantsComponent,
        StopSmokingHealthObjectiveDetailsComponent,
        StopSmokingHealthObjectiveDetailsListComponent
    ], imports: [CommonModule,
        SharedModule.forRoot(),
        TkUiComponentsModule,
        RouterModule,
        ReactiveFormsModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class ConsultantUiModule {
}
