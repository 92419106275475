import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {environment} from '../../../environments/environment';

import {UtilitiesService} from '../global/utilities.service';
import {UserService} from '../global/user.service';
import {
    BookAppointmentRequestInterface,
    DateTime, ProgramType, Tenant,
    WeekAvailabilityInterface,
    WeekAvailabilityRequestInterface
} from '../../interfaces/appointment.interface';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SupervisorAppointmentApiService {

    private readonly supervisorAppointmentApiUrls = {
        base: 'api/v1/',
        deleteAppointments: 'supervisor/availabilities',
        getAppointmentStatus: 'status',
        getAppointmentStatistics: 'supervisor/appointments/statistics',
        getAppointmentStatisticsMonthly: 'supervisor/appointments/statistics-monthly',
        getConsultantAvailabilityByDate: 'supervisor/availabilities/',
        getConsultants: 'consultants',
        getCsv: '/csv',
        postCreateAppointmentForUser: 'supervisor/availabilities',
        postDeleteAppointments: 'delete',
        postReassignAppointmentsToUser: 'supervisor/appointments/assignments',
        postReserve: 'supervisor/appointments',
        postBookAppointment: 'supervisor/appointments',
        postUpdateAvailabilityForConsultant: 'supervisor/availability-weeks',
        settings: 'settings',
    };

    constructor(private readonly http: HttpClient) {
    }

    public postBookAppointment(externalId: any, dateTime: string, tenant: Tenant, programType: ProgramType, consultantUsername: string): Observable<any> {
        const requestBody = {
            consultantUsername: consultantUsername,
            dateTime: dateTime,
            externalId: externalId,
            programType: programType,
            tenant: tenant
        };

        return this.http.post(`${environment.apiUrl}`
            + this.supervisorAppointmentApiUrls.base
            + this.supervisorAppointmentApiUrls.postBookAppointment,
            requestBody);
    }


    public postCreateAppointmentForUser(username: string, dates: any[], date: Date): Observable<any> {
        const dateTimes =  UtilitiesService.formatDateAndTimeForRequest(dates, UtilitiesService.formatDateForRequest(date));

        return this.http.post(`${environment.apiUrl}`
            + this.supervisorAppointmentApiUrls.base
            + this.supervisorAppointmentApiUrls.postCreateAppointmentForUser,
            {username, dateTimes});
    }

    public getConsultantAvailabilityByDate(name, dateString): Observable<any> {
        let httpParams = new HttpParams();

        if (name) {
            httpParams = httpParams.append('consultantUsername', name);
        }
        return this.http.get(`${environment.apiUrl}`
            + this.supervisorAppointmentApiUrls.base
            + this.supervisorAppointmentApiUrls.getConsultantAvailabilityByDate
            + dateString,
            {params: httpParams});
    }

    public postUpdateAvailabilityForConsultant(username: string, weeks: WeekAvailabilityRequestInterface[]): Observable<any> {
        return this.http.post(`${environment.apiUrl}`
            + this.supervisorAppointmentApiUrls.base
            + this.supervisorAppointmentApiUrls.postUpdateAvailabilityForConsultant,
            {weeks, username});
    }

    public deleteAppointments(dateTimes: DateTime[], username: string): Observable<any> {
        return this.http.delete(`${environment.apiUrl}`
            + this.supervisorAppointmentApiUrls.base
            + this.supervisorAppointmentApiUrls.deleteAppointments, {
            body: {dateTimes, username}});
    }

    public postReassignAppointmentsToUser(uuids: string[], consultantUsername: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}`
            + this.supervisorAppointmentApiUrls.base
            + this.supervisorAppointmentApiUrls.postReassignAppointmentsToUser,
            {
                uuids: uuids,
                toUsername: consultantUsername
            });
    }

    public getAppointmentStatistics(pagesize: number): Observable<any> {
        const params = new HttpParams().append('size', pagesize.toString());

        return this.http.get(`${environment.apiUrl}`
            + this.supervisorAppointmentApiUrls.base
            + this.supervisorAppointmentApiUrls.getAppointmentStatistics,
            {params: params});
    }

    public getAppointmentStatisticsMonthly(): Observable<any> {
        return this.http.get(`${environment.apiUrl}`
            + this.supervisorAppointmentApiUrls.base
            + this.supervisorAppointmentApiUrls.getAppointmentStatisticsMonthly);
    }

    public getAppointmentStatusByType(type: string, pageSize: number, page: number, sortBy?: string, ascending?: boolean): Observable<any> {
        const params = new HttpParams()
            .append('size', pageSize.toString())
            .append('page', (page - 1).toString());

        if (sortBy) {
            params.append('sortBy', sortBy);
        }

        if (typeof ascending !== 'undefined') {
            params.append('ascending', ascending.toString());
        }

        if (type === 'canceledByUser') {
          type = 'CANCELED_BY_USER';
        }

        if (type === 'canceledByConsultant') {
          type = 'CANCELED_BY_CONSULTANT';
        }

        params.append('type', type.toUpperCase());

        return this.http.get(`${environment.apiUrl}`
            + this.supervisorAppointmentApiUrls.base
            + this.supervisorAppointmentApiUrls.getAppointmentStatistics
            + '/' + type.toUpperCase(),
            {params: params});
    }

    public getConsultants(): Observable<any> {
        return this.http.get(`${environment.apiUrl}`
            + this.supervisorAppointmentApiUrls.base
            + this.supervisorAppointmentApiUrls.getConsultants);
    }

    public getSettings(programType: string): Observable<any> {
        const httpParams = new HttpParams().append('programType', programType);

        return this.http.get(`${environment.apiUrl}`
            + this.supervisorAppointmentApiUrls.base
            + this.supervisorAppointmentApiUrls.settings,
            {params: httpParams});
    }

    public postSettings(settings, programType: string): Observable<any> {
        const httpParams = new HttpParams().append('programType', programType);

        return this.http.post(`${environment.apiUrl}`
            + this.supervisorAppointmentApiUrls.base
            + this.supervisorAppointmentApiUrls.settings,
            settings,
            {params: httpParams});
    }

    public getStatisticsCsv(): Observable<any> {
        return this.http.get(`${environment.apiUrl}`
            + this.supervisorAppointmentApiUrls.base
            + this.supervisorAppointmentApiUrls.getAppointmentStatistics
            + this.supervisorAppointmentApiUrls.getCsv,
            {responseType: 'text', observe: 'response'});
    }
}
